<template>
    <div class="oauth-loading">
        <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

import Apis from '@/constants/apis'

import Oauth from '@/oauth'

export default {
    name: 'OauthCallback',
    methods: {
        onError (error) {
            this.showErrorToast(error.humanError)
            this.$router.push('/not-authorized')
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        Oauth.login()
        .then((user) => {
                this.$store.commit('dataUser/SET_USER', user)
                this.$http.setBearerToken(Apis.Ehpad, _.get(user, 'auth.access_token'))
                this.$acl.change(user.role)

                // Call API to flag user connection
                this.$store.dispatch('dataUser/login')
                .then((response) => {
                    const redirectUrl = _.get(user, 'state.redirectUrl', null)
                    // Security checks against CSRF attacks
                    if (redirectUrl && /^\//.test(redirectUrl)) {
                        this.$router.push(redirectUrl)
                    }
                    else {
                        this.$router.push('/residents')
                    }
                })
                .catch(this.onError.bind(this))
            },
            this.onError.bind(this)
        )
    }
}
</script>

<style lang="scss">
.oauth-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFF;
    display: block;
}
</style>
